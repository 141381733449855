import { create } from 'zustand';
import WebAppAPI from '../utils/WebAppAPI';
import { persist, createJSONStorage } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs
import { io } from 'socket.io-client';

// Connect to the WebSocket server
const socket = io('https://crafter-service-3f874b301901.herokuapp.com');

const useChatsStore = create(
  persist(
    (set, get) => ({
      conversation: [],
      inputValue: '',
      addChatObject: (userQuestion) => {
        const newChatObject = {
          id: uuidv4(),
          threadId: null,
          userQuestion,
          aiResponse: null,
          timestamp: new Date().toISOString(),
        };
        set((state) => ({
          conversation: [...state.conversation, newChatObject],
        }));
        return newChatObject.id; // Return the chat ID to update later
      },
      updateChatObject: (chatId, updatedFields) =>
        set((state) => ({
          conversation: state.conversation.map((chat) =>
            chat.id === chatId ? { ...chat, ...updatedFields } : chat
          ),
        })),
      setInputValue: (newValue) =>
        set(() => ({
          inputValue: newValue,
        })),
    }),
    {
      name: 'chats',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useChatsStore;

// Ensure the WebSocket connection is set up once at the start
if (!socket.connected) {
  socket.connect();
}

export const fetchAIResponseWithSocket = async (
  question,
  projectKey,
  epicId
) => {
  const { addChatObject, updateChatObject } = useChatsStore.getState();

  // Step 1: Add a new chat object with the user question
  const chatId = addChatObject(question);

  // Step 2: Ensure we are connected to the WebSocket server
  socket.on('connect', () => {
    console.log('Connected to WebSocket:', socket.id);
  });

  // Step 3: Set up a listener for this specific chat instance
  const responseListener = (data) => {
    // console.log('Real-time insights:', data);

    const { conversation } = useChatsStore.getState();
    const currentChat = conversation.find((chat) => chat.id === chatId);

    if (currentChat) {
      let { message } = data;

      // Append message to aiResponse
      const updatedAiResponse =
        currentChat.aiResponse === null
          ? message
          : `${currentChat.aiResponse}${message}`;

      // If "Streaming completed." is at the very end, remove it
      const cleanedResponse = updatedAiResponse.endsWith('Streaming completed.')
        ? updatedAiResponse.slice(0, -'Streaming completed.'.length)
        : updatedAiResponse;

      updateChatObject(chatId, {
        aiResponse: cleanedResponse,
      });

      // Stop listening once streaming is complete
      if (message === 'Streaming completed.') {
        socket.off('insightsResponse', responseListener);
      }
    }
  };

  // Attach the event listener but remove it after completion
  socket.on('insightsResponse', responseListener);

  try {
    // Step 4: Make the API request to get insights
    const insights = await WebAppAPI().post(
      `/reports/${projectKey}/${epicId}/chat`,
      { prompt: question }
    );

    // Step 5: Emit the request to WebSocket
    socket.emit('getInsights', {
      prompt: question,
      projectKey,
      epicId,
      chatId,
    });

    updateChatObject(chatId, {
      threadId: insights.data.threadId,
    });
  } catch (error) {
    console.error('Error fetching insights from API:', error);

    updateChatObject(chatId, {
      aiResponse: 'Error occurred while fetching insights from the API.',
      threadId: null,
    });

    socket.off('insightsResponse', responseListener); // Remove listener on error
  }

  // Handle connection errors
  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    updateChatObject(chatId, {
      aiResponse: 'Error occurred while connecting to WebSocket.',
      threadId: null,
    });

    socket.off('insightsResponse', responseListener); // Cleanup
  });
};
