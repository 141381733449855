import React, { useEffect, useState, useRef } from 'react';
import PdfIcon2 from '../../icons/files/PdfIcon2';
import CSVIcon from '../../icons/files/CSVIcon';
import CalendarIcon from '../../components/ui/CalendarIcon';
import Timestamp from '../../icons/Timestamp';
import useAuthStore from '@/store/Auth';
import Charts from './Charts/Charts';
import ProjectDropdown from '../../components/ui/ProjectDropdown';
import WebAppService from '@/services/WebAppService';
import useInsightsStore from '../../store/Insights';
import { motion } from 'framer-motion';
import EpicDropdown from '../../components/ui/EpicDropdown';
import DateSince2 from '../../components/DateSince/DateSince2';
import RefreshIcon, { Refresh } from '../../icons/RefreshIcon';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PromptInput1 from '../../components/ui/PromptInput1/PromptInput1';
import ChatsSection from './Chats/ChatsSection';
// import { Tooltip } from 'react-tooltip';
import { Tooltip } from 'radix-ui';
import InsightsTourGuide from '../../tours/InsightsTourGuide/InsightsTourGuide';
import useChatsStore, {
  fetchAIResponse,
  fetchAIResponseWithSocket,
} from '../../store/Chats';
import CustomSpinner from '../../components/common/Custom/CustomSpinner/CustomSpinner';
import { Link } from 'react-router-dom';

const items = [];
const issues = [];
export const Body2 = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const authStore = useAuthStore();
  const {
    jiraProjects,
    jiraProjectEpics,
    jiraProjectEpicChildren,
    selectedProject,
    selectedEpic,
    updateSelectedProject,
    updateSelectedEpic,
    updateJiraProjects,
    updateJiraProjectEpics,
    updateJiraProjectEpicChildren,
    insightActiveComponent,
    setInsightActiveComponent,
  } = useInsightsStore();
  const { setInputValue } = useChatsStore();
  const { user } = authStore;
  const organizationId = user.organization;
  const selectedProjectKey = selectedProject.key;
  const epicId = selectedEpic?.key;
  const [startTour, setStartTour] = useState(false);
  const [showDataPrivacy, setShowDataPrivacy] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const chartsRef = useRef(null);
  const headerRef = useRef(null);
  const projectDetailsRef = useRef(null);

  // useEffect(() => {
  //   fetchAIResponseWithSocket(
  //     'What are the critical issues with this epic',
  //     'CS',
  //     'CS-704'
  //   );
  // }, []);

  const fetchJiraProjects = async (organizationId) => {
    try {
      const response =
        await WebAppService.getOrganizationJiraProjects(organizationId);

      const jiraProjectsData = response.data.values;

      updateJiraProjects(jiraProjectsData);
    } catch (error) {
      console.error('Error getting Jira keys', error);
    }
  };

  const fetchJiraProjectEpics = async (selectedProjectKey) => {
    try {
      const response =
        await WebAppService.getJiraProjectEpics(selectedProjectKey);
      const epicsData = response.data;

      // Check if current selected epic exists in the new epicsData
      const epicExists = epicsData.issues.find(
        (epic) => epic.key === selectedEpic?.key
      );

      if (epicExists) {
        updateSelectedEpic(epicExists);
      } else if (selectedEpic) {
        updateSelectedEpic(null);
      }

      updateJiraProjectEpics(epicsData);
    } catch (error) {
      console.error('Error getting Jira project epics', error);
    }
  };

  const handleGetEpicChildren = async (selectedProjectKey, epicId) => {
    try {
      setIsRefreshing(true);
      const response = await WebAppService.getChildrenOfAnEpic(
        selectedProjectKey,
        epicId
      );
      const epicChildren = response.data;

      // console.log('Epic Children Data', epicChildren);
      updateJiraProjectEpicChildren(epicChildren);
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    if (selectedProjectKey) {
      fetchJiraProjectEpics(selectedProjectKey);
    }
    if (selectedProjectKey && epicId) {
      handleGetEpicChildren(selectedProjectKey, epicId);
    }
  }, [selectedProjectKey, epicId]);

  useEffect(() => {
    const hasViewedInsights = localStorage.getItem('hasViewedInsights');
    setStartTour(!hasViewedInsights);
  }, []);

  useEffect(() => {
    const hasViewedDataPrivacy = localStorage.getItem('hasViewedDataPrivacy');
    setShowDataPrivacy(!hasViewedDataPrivacy);
  }, []);

  const handleCloseDataPrivacy = () => {
    localStorage.setItem('hasViewedDataPrivacy', 'true');
    setShowDataPrivacy(false);
  };

  const handleTourEnd = () => {
    localStorage.setItem('hasViewedInsights', 'true');
    setStartTour(false);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  const tabOptions = [
    {
      label: 'What are the critical issues with this project?',
      value: 'What are the critical issues with this project?',
      icon: PromptIcon,
    },
    {
      label: 'How can I improve the project progress?',
      value: 'How can I improve the project progress?',
      icon: PromptIcon,
    },
  ];

  const renderActiveComponent = () => {
    if (insightActiveComponent === 'insights') {
      return (
        <div
          className={`min-w-[1200px] overflow-x-scroll w-full h-screen pt-[65px] pb-[45px] px-4 text-sm text-white-1 overflow-hidden`}
        >
          <div className="flex relative bg3 flex-col gap-3 w-full h-full pt-4 px-8 pb-4 mt-8 mb-8 border border-gray-2/30 rounded-md overflow-scroll innerFrameBg">
            {startTour && (
              <InsightsTourGuide
                start={startTour}
                setStartTour={setStartTour}
                onTourEnd={handleTourEnd}
              />
            )}

            {showDataPrivacy && (
              <div className="flex flex-row items-center justify-between gap-4 w-full px-5 py-4 rounded-xl insights_bg_1">
                <p className="text-white-1">
                  {/* <span className="mr-2">👉🏾</span> */}
                  Your data privacy matters. We don't train models on your
                  data. 
                  <span className=" ml-1">
                    <Link
                      to={
                        'https://help.openai.com/en/articles/7232945-how-can-i-use-the-chat-completion-api'
                      }
                      target="_blank"
                      className="underline underline-offset-1 text-blue-1 font-medium"
                    >
                      View Open AI's Policy
                    </Link>
                  </span>
                </p>

                <button onClick={handleCloseDataPrivacy}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="#FF9595"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.17004 14.83L14.83 9.17"
                      stroke="#FF9595"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.83 14.83L9.17004 9.17"
                      stroke="#FF9595"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            )}

            {/* --------------------- Heading ----------------------------- */}

            <div className="flex flex-col gap-4 w-full px-5 py-4 rounded-xl insights_bg_1 mt-3">
              <header className="w-full flex items-center justify-between">
                <div className=" w-fit flex flex-col gap-[6px]">
                  <h2 className="font-bold lg:text-[16px] xl:text-[16px]">
                    Your Jira Insights
                  </h2>
                  <p className="text-gray-6 w-full lg:text-[14px] xl:text-[14px]">
                    A report overview of your projects and tasks
                  </p>
                </div>

                {selectedEpic && (
                  <div className="w-fit flex justify-end mt-4 text-gray-4">
                    <span className="w-full flex gap-2 items-center">
                      <Timestamp />
                      <p className="font-bold w-fit">Project age: </p>
                      {selectedEpic.created && (
                        <DateSince2 date={selectedEpic.created} />
                      )}
                    </span>
                  </div>
                )}
              </header>

              <div ref={projectDetailsRef} className="w-full">
                <div className="w-full items-center flex gap-4 justify-between">
                  <div className="w-3/4 z-20 flex gap-4 items-center">
                    <div className="relative">
                      <ProjectDropdown
                        isProject
                        options={jiraProjects}
                        fetchData={() => fetchJiraProjects(organizationId)}
                        fetchJiraProjectEpics={fetchJiraProjectEpics}
                        placeholder="Select your Jira project"
                        onChange={updateSelectedProject}
                        value={selectedProject}
                      />
                    </div>

                    {startTour ||
                    (jiraProjectEpics.issues &&
                      jiraProjectEpics.issues.length > 0) ? (
                      <motion.div
                        initial={{ y: 10 }}
                        animate={{ y: 0 }}
                        exit={{ y: -50 }}
                        transition={{ duration: 0.2 }}
                        className="w-[400px] epic-dropdown"
                      >
                        <EpicDropdown
                          options={jiraProjectEpics.issues || []}
                          placeholder="Select the epic"
                          onChange={updateSelectedEpic}
                          value={selectedEpic}
                          setIsRefreshing={setIsRefreshing}
                        />
                      </motion.div>
                    ) : // <div className="w-[20px] h-[20px] mt-2">
                    //   <CustomSpinner />
                    // </div>
                    null}
                  </div>
                  <div id="last-sync" className="w-fit">
                    <div
                      className={`flex w-fit items-center gap-2 justify-between text-gray-3 text-white bg-transparent rounded-md py-[10px] px-[14px] border-1 border-black-1 focus:outline-none`}
                    >
                      <span className="w-fit flex flex-row gap-2 items-center flex-shrink-0">
                        <div className="text-gray-4">
                          <CalendarIcon />
                        </div>
                        <p className="flex-shrink-0 text-gray-4">
                          Last sync
                          {jiraProjectEpicChildren?.refreshTime &&
                            ': ' + jiraProjectEpicChildren?.refreshTime}
                        </p>
                        {selectedEpic && (
                          <Tooltip.Provider>
                            <Tooltip.Root>
                              <Tooltip.Trigger asChild>
                                <button
                                  data-tooltip-id="refresh-tooltip"
                                  data-tooltip-place="bottom"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (selectedEpic !== '') {
                                      fetchJiraProjectEpics(selectedProjectKey);
                                      handleGetEpicChildren(
                                        selectedProjectKey,
                                        epicId
                                      );
                                    }
                                  }}
                                  disabled={selectedEpic === ''}
                                  className={` hover:text-white-1 pr-[14px] flex gap-2 items-center flex-shrink-0 ml-3 transition-colors duration-500 relative ${
                                    selectedEpic === ''
                                      ? 'opacity-50 cursor-not-allowed'
                                      : 'cursor-pointer'
                                  }`}
                                >
                                  <div
                                    className={`refresh-icon ${
                                      isRefreshing ? 'rotate' : ''
                                    }`}
                                  >
                                    <Refresh />
                                  </div>
                                  <p className="text-xs pr-[24px]">
                                    Click to refresh
                                  </p>
                                </button>
                              </Tooltip.Trigger>
                              <Tooltip.Portal>
                                <Tooltip.Content
                                  side="bottom"
                                  className="mt-[10px] ml-[-320px] border-gray-2/20 select-none rounded-2xl bg-black-1 px-[15px] py-1.5 text-[15px] leading-none text-black-1 will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
                                  sideOffset={5}
                                >
                                  <RefreshTooltip />
                                  <Tooltip.Arrow className="text-white-1 fill-black-1 -ml-[150px]" />
                                </Tooltip.Content>
                              </Tooltip.Portal>
                            </Tooltip.Root>
                          </Tooltip.Provider>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----------- What can Crafter do for you??? -------------- */}

            <div className="p-5 flex flex-col gap-3">
              <div id="chat-input" className="flex flex-col gap-3">
                <p
                  className={`text-center font-bold text-2xl text-white-2 ${selectedEpic || startTour ? '' : ''}`}
                >
                  What can Crafter help you with today?
                </p>

                <div className="flex mx-auto w-[70%]">
                  <PromptInput1 isTourStarted={startTour} />
                </div>
              </div>

              <div
                className={`mt-5 flex flex-col gap-3 justify-center mx-auto w-full ${selectedEpic ? '' : ''}`}
              >
                {selectedEpic && (
                  <h4 className="text-center text-sm text-white-2">
                    Here are some suggestions
                  </h4>
                )}

                <div
                  className={`flex ${selectedEpic ? 'w-[65%' : 'w-[45%]'} gap-4 justify-center mx-auto`}
                >
                  {tabOptions.map((item, index) => (
                    <button
                      disabled={!selectedEpic}
                      onClick={() => setInputValue(item.label)}
                      className="w-fit flex flex-shrink-0 items-center py-3 px-4 gap-3 border-0.5 border-gray-2 rounded-full cursor-pointer hover:bg-black-1 transition-all duration-500 disabled:cursor-not-allowed"
                      key={index}
                    >
                      <item.icon />
                      <p className="text-gray-4 w-full">{item.label}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* --------------------------- Charts ------------------------------ */}

            <div className="w-full flex flex-row gap-5 justify-center">
              <div
                id="insight-charts"
                className="cursor-pointer chart-container-bg rounded-xl w-[80%] h-[300px] px-[18px] py-[10px]"
              >
                <Charts ref={chartsRef} />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (insightActiveComponent === 'chats') {
      return <ChatsSection />;
    }
    return null;
  };

  return <>{renderActiveComponent()}</>;
};

const PromptIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1414 4.07499L11.2581 1.89999C10.5414 1.58332 9.45807 1.58332 8.74141 1.89999L3.85807 4.07499C2.62474 4.62499 2.44141 5.37499 2.44141 5.77499C2.44141 6.17499 2.62474 6.92499 3.85807 7.47499L8.74141 9.64999C9.09974 9.80832 9.54974 9.89166 9.99974 9.89166C10.4497 9.89166 10.8997 9.80832 11.2581 9.64999L16.1414 7.47499C17.3747 6.92499 17.5581 6.17499 17.5581 5.77499C17.5581 5.37499 17.3831 4.62499 16.1414 4.07499Z"
        fill="#A1C181"
      />
      <path
        opacity="0.4"
        d="M10.0003 14.2C9.68359 14.2 9.36693 14.1333 9.07526 14.0083L3.45859 11.5083C2.60026 11.125 1.93359 10.1 1.93359 9.15834C1.93359 8.81667 2.20859 8.54167 2.55026 8.54167C2.89193 8.54167 3.16693 8.81667 3.16693 9.15834C3.16693 9.60834 3.54193 10.1917 3.95859 10.375L9.57526 12.875C9.84193 12.9917 10.1503 12.9917 10.4169 12.875L16.0336 10.375C16.4503 10.1917 16.8253 9.61667 16.8253 9.15834C16.8253 8.81667 17.1003 8.54167 17.4419 8.54167C17.7836 8.54167 18.0586 8.81667 18.0586 9.15834C18.0586 10.0917 17.3919 11.125 16.5336 11.5083L10.9169 14.0083C10.6336 14.1333 10.3169 14.2 10.0003 14.2Z"
        fill="#A1C181"
      />
      <path
        opacity="0.4"
        d="M10.0003 18.3333C9.68359 18.3333 9.36693 18.2667 9.07526 18.1417L3.45859 15.6417C2.53359 15.2333 1.93359 14.3083 1.93359 13.2917C1.93359 12.95 2.20859 12.675 2.55026 12.675C2.89193 12.675 3.16693 12.95 3.16693 13.2917C3.16693 13.8167 3.47526 14.2917 3.95859 14.5083L9.57526 17.0083C9.84193 17.125 10.1503 17.125 10.4169 17.0083L16.0336 14.5083C16.5086 14.3 16.8253 13.8167 16.8253 13.2917C16.8253 12.95 17.1003 12.675 17.4419 12.675C17.7836 12.675 18.0586 12.95 18.0586 13.2917C18.0586 14.3083 17.4586 15.225 16.5336 15.6417L10.9169 18.1417C10.6336 18.2667 10.3169 18.3333 10.0003 18.3333Z"
        fill="#A1C181"
      />
    </svg>
  );
};

const RefreshTooltip = () => (
  <div className="tooltip-content flex items-start gap-2  py-1 overflow-hidden w-[350px]">
    <div className="w-[20px] text-[#56ACC7]">
      <RefreshIcon />
    </div>
    <div>
      <p className="text-sm font-bold text-white-1">Refresh to sync?</p>
      <p className="text-xs text-gray-500 leading-[18px] mt-0.5">
        Refreshing your insight will sync the latest updates to the insight
        chart
      </p>
    </div>
  </div>
);

const Skeleton = () => {
  return (
    <div className="w-full flex py-3 px-4 gap-3 border-0.5 border-gray-2 rounded-full bg-black-1">
      <div className="w-full h-[20px] "> </div>
    </div>
  );
};
