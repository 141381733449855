import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialState = {
  kycModal: false,
  temporaryChat: false,
  deleteIntegration: false,
  updateAPIKeyModal: false,
  openAIActiveComponent: '',
  jiraActiveComponent: '',
  integrationSuccessModal: false,
  integrationFailedModal: false,
};

const useActionStore = create(
  persist(
    (set) => ({
      ...initialState,
      toggleTemporaryChat: (isOpen) => set({ temporaryChat: isOpen }),
      toggleSelectAnEpic: (isOpen) => set({ selectAnEpic: isOpen }),
      toggleDeleteIntegration: (isOpen) => set({ deleteIntegration: isOpen }),
      toggleUpdateAPIKeyModal: (isOpen) => set({ updateAPIKeyModal: isOpen }),

      toggleKycModal: (isOpen) => set({ kycModal: isOpen }),
      toggleIntegrationSuccessModal: (isOpen) =>
        set({ integrationSuccessModal: isOpen }),
      toggleIntegrationFailedModal: (isOpen) =>
        set({ integrationFailedModal: isOpen }),

      toggleTemporaryChatState: () =>
        set((state) => ({
          temporaryChat: !state.temporaryChat,
        })),

      toggleSelectAnEpicState: () =>
        set((state) => ({
          selectAnEpic: !state.selectAnEpic,
        })),

      toggleDeleteIntegrationState: () =>
        set((state) => ({
          deleteIntegration: !state.deleteIntegration,
        })),
      toggleUpdateAPIKeyModalState: () =>
        set((state) => ({
          updateAPIKeyModal: !state.updateAPIKeyModal,
        })),
      toggleKycModalState: () =>
        set((state) => ({
          kycModal: !state.kycModal,
        })),

      toggleIntegrationSuccessModalState: () =>
        set((state) => ({
          integrationSuccessModal: !state.integrationSuccessModal,
        })),
      toggleIntegrationFailedModalState: () =>
        set((state) => ({
          integrationFailedModal: !state.integrationFailedModal,
        })),

      setOpenAIActiveComponent: (component) =>
        set({ openAIActiveComponent: component }),

      setJiraActiveComponent: (component) =>
        set({ jiraActiveComponent: component }),
    }),
    {
      name: 'action',
      getStorage: () => createJSONStorage(() => sessionStorage),
    }
  )
);

export default useActionStore;
