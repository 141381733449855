export const formatDatesDmy = (dateString) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const d = new Date(dateString);
  const day = d.getDate();
  const daySuffix = getDaySuffix(day);
  const month = months[d.getMonth()];
  const year = d.getFullYear();

  return `${day}${daySuffix}, ${month} ${year}`;
};

const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
export const formatResponse = (response) => {
  if (typeof response !== 'string') return response;

  // Split the response by lines and process each line
  const lines = response
    .split('\n')
    .map((line) => line.trim())
    .filter(Boolean);

  const formattedResponse = [];
  let currentList = null;

  for (const line of lines) {
    if (/^\d+\.\s/.test(line)) {
      // Matches numbered lists (e.g., "1. Key: CS-720, Summary: ...")
      if (!currentList) {
        currentList = [];
        formattedResponse.push(currentList);
      }
      currentList.push(line);
    } else if (/^[-*]\s/.test(line)) {
      // Matches bullet lists (e.g., "- Key: CS-720, Summary: ...")
      if (!currentList) {
        currentList = [];
        formattedResponse.push(currentList);
      }
      currentList.push(line);
    } else {
      // Check for bold markers "**text**"
      const boldFormattedLine = line.replace(
        /\*\*(.+?)\*\*/g,
        '<strong>$1</strong>'
      );

      // Check for Markdown-style links "[text](url)"
      const linkFormattedLine = boldFormattedLine.replace(
        /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
        (match, text, url) => `<a href="${url}" target="_blank">${text}</a>`
      );

      // Non-list line, process bold formatting and links if applicable
      if (currentList) {
        currentList = null; // End current list
      }
      formattedResponse.push(linkFormattedLine);
    }
  }

  return formattedResponse;
};

export const formatBoldText = (text) => {
  // Replace **Text** with <strong>Text</strong>
  return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
};

export function exportToCSV(data) {
  const headers = ['Metric', 'Value'];
  const rows = [
    [
      'Active Percentage',
      Math.round(data.completionStatus?.activePercentage) ?? 'N/A',
    ],
    [
      'Completed Percentage',
      Math.round(data.completionStatus?.completedPercentage) ?? 'N/A',
    ],
    [
      'Dormant Percentage',
      Math.round(data.completionStatus?.dormantPercentage) ?? 'N/A',
    ],
    [
      'Not Started Percentage',
      Math.round(data.completionStatus?.notStartedPercentage) ?? 'N/A',
    ],
    [
      'To Do Percentage',
      Math.round(data.completionStatus?.toDoPercentage) ?? 'N/A',
    ],
    [
      'Unassigned Percentage',
      Math.round(data.completionStatus?.unassignedPercentage) ?? 'N/A',
    ],
    ['Count', data.count ?? 'N/A'],
    ['Progress Percentage', Math.round(data.progressPercentage) ?? 'N/A'],
    ['Project Key', data.projectKey ?? 'N/A'],
    [
      'Refresh Time',
      data.refreshTime ? new Date(data.refreshTime).toLocaleString() : 'N/A',
    ],
  ];

  // Define headers and rows for issues data with safe access
  const issueHeaders = [
    'Assignee',
    'Created At',
    'Issue URL',
    'Key',
    'Status',
    'Summary',
    'Updated At',
  ];

  const issues = Array.isArray(data.issues) ? data.issues : [];

  // Convert issues array into rows
  const issueRows = issues.map((issue) => [
    issue.assignee?.name || 'Unassigned',

    `"${new Date(issue?.createdAt).toLocaleString()}"`,
    issue?.issueUrl,
    issue?.key,
    issue?.status,
    issue?.summary,
    `"${new Date(issue?.updatedAt).toLocaleString()}"`,
  ]);

  // Combine main data and issues into a single CSV format
  let csvContent = headers.join(',') + '\n';
  rows.forEach((row) => {
    csvContent += row.join(',') + '\n';
  });

  // Add a blank line before issues section and include issue headers
  csvContent += '\n' + issueHeaders.join(',') + '\n';
  issueRows.forEach((row) => {
    csvContent += row.join(',') + '\n';
  });

  // Create a Blob for download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'project_data_with_issues.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const formatMarkdownList = (text) => {
  return text.replace(/(\d+)\.\s+/g, '\n$1. ');
};
