import React, { useState } from 'react';
import CustomModalOverlay from '../../../common/Custom/CustomModalOverlay/CustomModalOverlay';
import useActionStore from '../../../../store/Actions';
import GlobalButton from '../../../ui/GlobalButton';
import useAuthStore from '../../../../store/Auth';
import { useToast } from '../../../../context/ToastContext';
import WebAppService from '../../../../services/WebAppService';
import useIntegrationsStore from '../../../../store/Integrations';
import CustomModalOverlay2 from '../../../common/Custom/CustomModalOverlay2/CustomModalOverlay2';

export default function DeleteIntegrationModal() {
  const authStore = useAuthStore();
  const { toggleDeleteIntegration, deleteIntegration } = useActionStore();
  const { selectedIntegration } = useIntegrationsStore();
  const [deleting, setDeleting] = useState(false);
  const { addToast } = useToast();

  const handleClose = () => {
    toggleDeleteIntegration(false);
  };

  const deleteOpenAI = async () => {
    const organizationId = authStore.organization.id;
    setDeleting(true);

    try {
      const deleteResponse = await WebAppService.deleteOpenAI(organizationId);
      if (deleteResponse.success) {
        addToast('Integration deleted successfully', 'success', 3000);
      }

      const { data } = deleteResponse;
      // console.log(data);

      authStore.updateOrganization({
        hasSetOpenAIConfig: data.has_set_openai_api_key,
        hasSetJiraConfig: data.has_set_jira_api_key,
        domain: data.domain,
        name: data.name,
        updatedAt: data.updatedAt,
        jira_url: data.jira_url,
        jira_admin_email: data.jira_admin_email,
        id: data.id,
      });
    } catch (error) {
      addToast(
        `${error?.response?.data?.error || 'Something went wrong'}`,
        'error',
        3000
      );

      console.error(error);
    }
    setDeleting(false);
    handleClose();
  };

  const handleDeleteIntegration = (integration) => {
    switch (integration) {
      case 'OpenAI':
        deleteOpenAI();
        break;
      case 'twitch':
        deleteOpenAI();
        break;
      case 'youtube':
        deleteOpenAI();
        break;
      default:
    }
  };
  return (
    <CustomModalOverlay2
      open={deleteIntegration}
      onClose={() => {
        toggleDeleteIntegration(false);
      }}
      width="650px"
      radius="12px"
      withCloseButton={false}
    >
      <div className="flex flex-col gap-8">
        <div className="w-full flex flex-col gap-2 items-start">
          <h1 className="font-semibold text-[20px] text-white-1">
            Delete {selectedIntegration} integration?
          </h1>
          <p className="text-gray-4">
            By deleting your {selectedIntegration} integration, you will no
            longer have access to your {selectedIntegration} projects in Crafter
          </p>
        </div>
        <div className="w-3/4 flex grow">
          <GlobalButton
            onClick={handleClose}
            variant={'blue'}
            state={'default'}
            size={'lg'}
          >
            Cancel
          </GlobalButton>
          <GlobalButton
            onClick={() => handleDeleteIntegration(selectedIntegration)}
            variant={'transparent'}
            state={deleting ? 'disabled' : 'default'}
            size={'lg'}
            className={`text-error-2`}
            disabled={deleting}
          >
            {deleting ? 'Deleting...' : 'Delete integration'}
          </GlobalButton>
        </div>
      </div>
    </CustomModalOverlay2>
  );
}
