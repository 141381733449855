import React from 'react';
import CustomModalOverlay from '../../common/Custom/CustomModalOverlay/CustomModalOverlay';
import useActionStore from '../../../store/Actions';
import ticketsGif from '../../../assets/ticketsGif.gif';

export default function SelectAnEpicModal() {
  const { toggleSelectAnEpic, selectAnEpic } = useActionStore();
  return (
    <CustomModalOverlay
      open={selectAnEpic}
      onClose={() => {
        toggleSelectAnEpic(false);
      }}
      width="550px"
      withCloseButton={true}
    >
      <div className="">
        <div className="flex flex-col items-center">
          <img
            src={ticketsGif}
            alt="ticketsGif"
            width={'150'}
            className="opacity-70"
          />

          <div className="flex flex-col gap-1 text-center">
            <h1 className="font-bold text-xl">You need to select a project</h1>

            <p className="text-gray-4 text-sm">
              You need to select a Jira project and epic to chat with Crafter
            </p>
          </div>
        </div>
      </div>
    </CustomModalOverlay>
  );
}
