import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft } from '../../../icons/svgs';
import useInsightsStore from '../../../store/Insights';
import styles from './ChatsSection.module.css';
import { ChatReply2 } from './ChatReply/ChatReply2';
import { ChatSend } from './ChatSend/ChatSend';
import chatLoading from '../../../assets/chatLoadingGif.gif';
import PromptInput2 from '../../../components/ui/PromptInput2/PromptInput2';
import useActionStore from '../../../store/Actions';
import useChatsStore from '../../../store/Chats';
import { formatResponse } from '../../../utils/helpers/helpers';
import { motion } from 'framer-motion';
import { monitorSessionStorageSpace } from '../../../utils/helpers/sessionStorageMonitor';
import Timestamp from '../../../icons/Timestamp';
import DateSince2 from '../../../components/DateSince/DateSince2';
import { ProjectsIcon2 } from '../../../components/ui/ProjectDropdown';

export default function ChatsSection() {
  const { insightActiveComponent, setInsightActiveComponent, selectedEpic } =
    useInsightsStore();
  const { conversation } = useChatsStore();
  const { toggleTemporaryChat } = useActionStore();

  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activePrompt, setActivePrompt] = useState(0);
  const mainRef = useRef(null);
  const loadingRef = useRef(null);

  const handleBack = () => {
    setInsightActiveComponent('insights');
  };

  const handlePromptClick = (index) => {
    setActivePrompt(index);
    const section = document.getElementById(`section-${index}`);
    if (section && mainRef.current) {
      const offset = 15;
      mainRef.current.scrollTo({
        top: section.offsetTop - offset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const mainElement = mainRef.current;
    const offset = 15;

    const handleScroll = () => {
      if (mainElement) {
        const scrollTop = mainElement.scrollTop;
        let activeIndex = 0;

        conversation.forEach((_, index) => {
          const section = document.getElementById(`section-${index}`);
          if (section) {
            const sectionTop = section.offsetTop - offset;
            if (scrollTop >= sectionTop) {
              activeIndex = index;
            }
          }
        });

        // setActivePrompt(activeIndex);
      }
    };

    if (mainElement) {
      mainElement.addEventListener('scroll', handleScroll);
      return () => mainElement.removeEventListener('scroll', handleScroll);
    }
  }, [conversation]);

  const handleScroll = () => {
    if (mainRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      setShowScrollButton(scrollTop + clientHeight < scrollHeight - 50);
    }
  };

  const scrollToBottom = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({
        top: mainRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const hasViewedTemporaryChatModal = sessionStorage.getItem(
      'hasViewedTemporaryChatModal'
    );
    if (!hasViewedTemporaryChatModal) {
      toggleTemporaryChat(true);
    }
  }, []);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [conversation]);

  useEffect(() => {
    if (loadingRef.current) {
      const timer = setTimeout(() => {
        scrollToBottom();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [conversation]);

  useEffect(() => {
    const mainElement = mainRef.current;
    if (mainElement) {
      mainElement.addEventListener('scroll', handleScroll);
      return () => mainElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    // Define the pruning logic for the "chats" key
    const pruneLogic = (data) => {
      // Example: Remove the oldest chats to free space
      if (Array.isArray(data)) {
        return data.slice(1); // Remove the first (oldest) chat
      }
      console.warn('Data is not in expected array format.');
      return data;
    };

    // Initialize the monitoring function
    const manageStorageSpace = monitorSessionStorageSpace(
      10,
      'chats',
      pruneLogic
    );

    // Set up an interval to monitor storage every 5 seconds
    const intervalId = setInterval(() => {
      manageStorageSpace();
    }, 5000);

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={`min-w-[1000px] overflow-x-scroll w-full min-h-screen pt-[65px] pb-[45px] px-4 text-sm text-white-1 overflow-hidden`}
    >
      <div className="flex bg3 flex-col gap-8 w-full h-full pt-8 px-8 pb-4 mt-8 mb-8 border border-gray-2/30 rounded-md innerFrameBg">
        <div
          onClick={handleBack}
          className="w-fit  flex flex-row gap-2 text-white-3 cursor-pointer"
        >
          <div className="w-4">
            <ArrowLeft />
          </div>
          <button className="w-fit font-semibold">Go back</button>
        </div>

        <div className="w-full flex items-center justify-between">
          <div>
            <div className=" flex flex-row gap-2 w-fit min-w-48 border-b-0.5 border-gray-5 rounded-lg px-4 py-2">
              <ProjectsIcon2 />
              <h2 className="font-semibold text-white-3">
                {selectedEpic.key} : {selectedEpic.summary}
              </h2>
            </div>
          </div>
          <div>
            {selectedEpic && (
              <div className="w-fit flex justify-end mt-4 text-gray-4">
                <span className="w-full flex gap-2 items-center">
                  <Timestamp />
                  <p className="font-bold w-fit">Project age: </p>
                  {selectedEpic.created && (
                    <DateSince2 date={selectedEpic.created} />
                  )}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="h-[85%] flex flex-row gap-8 w-full">
          <div
            className={`min-h-[200px] h-full flex flex-col w-[73%] border rounded-lg relative ${styles.chat_container_bg}`}
          >
            <header className="w-full">
              <div className="w-full border-b-1 border-gray-2 px-4 py-4">
                {/* <p>
                  {activePrompt + 1} of {conversation.length} prompts
                </p> */}
              </div>
            </header>

            <main
              style={{
                height: 'calc(100vh - 430px)',
              }}
              ref={mainRef}
              className="p-4  overflow-scroll flex flex-col gap-4 mt-auto relative"
            >
              <div id="prompt-1" className="mt-auto">
                {conversation.map((message, index) => (
                  <div key={message.id}>
                    <div id={`section-${index}`}>
                      {message.userQuestion && (
                        <ChatSend
                          content={message.userQuestion}
                          timestamp={message.timestamp}
                        />
                      )}
                      {message.aiResponse === null ||
                      message.aiResponse === undefined ? (
                        <img
                          ref={loadingRef}
                          className="mb-5"
                          alt="loading"
                          src={chatLoading}
                          width={80}
                        />
                      ) : (
                        <ChatReply2
                          content={message.aiResponse}
                          timestamp={message.timestamp}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </main>

            <div className="p-4 w-full bottom-4 z-20">
              <PromptInput2 scrollToBottom={scrollToBottom} />
            </div>

            {showScrollButton && (
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
                onClick={scrollToBottom}
                className={`absolute bottom-[115px] left-1/2 transform -translate-x-1/2 bg-black-3 text-white-2 rounded-full p-1 shadow-md ${styles.send_chat_bg}`}
              >
                <ChatArrowDown />
              </motion.button>
            )}
          </div>
          <div
            className={`flex flex-col w-[27%] h-auto border rounded-lg py-5 px-5 ${styles.chat_container_bg}`}
          >
            <div className="pb-5 border-b flex flex-col gap-2 border-[#252525]">
              <h2 className="font-bold text-lg text-white-2">
                Your prompt history
              </h2>
              <p className="text-xs text-white-3 leading-[20px]">
                Easily get back to your previous conversations with Crafter by
                selecting the prompts{' '}
              </p>
            </div>
            <div className="flex flex-col gap-4 mt-5 overflow-auto">
              <div className="flex flex-col gap-4">
                {conversation.map((chat, index) => (
                  <div
                    key={chat.id}
                    className={`rounded-2xl p-3 cursor-pointer ${
                      activePrompt === index ? 'border-2 border-gray-4' : ''
                    } ${styles.prompt_container_bg}`}
                    onClick={() => handlePromptClick(index)}
                  >
                    <p className="text-gray-6 truncate">{chat.userQuestion}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ChatArrowDown = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.517 4.46777C10.6947 4.46777 10.8652 4.53839 10.9909 4.66408C11.1166 4.78977 11.1872 4.96025 11.1872 5.13801V14.2442L14.5116 10.9199C14.5729 10.854 14.6469 10.8012 14.7291 10.7646C14.8113 10.7279 14.9001 10.7082 14.9901 10.7067C15.0801 10.7051 15.1695 10.7216 15.2529 10.7553C15.3364 10.789 15.4122 10.8392 15.4758 10.9029C15.5395 10.9665 15.5896 11.0423 15.6234 11.1258C15.6571 11.2092 15.6736 11.2986 15.672 11.3886C15.6704 11.4786 15.6507 11.5673 15.6141 11.6496C15.5775 11.7318 15.5247 11.8058 15.4588 11.8671L10.9906 16.3353C10.8649 16.4609 10.6946 16.5314 10.517 16.5314C10.3394 16.5314 10.169 16.4609 10.0433 16.3353L5.57514 11.8671C5.50929 11.8058 5.45647 11.7318 5.41984 11.6496C5.38321 11.5673 5.36351 11.4786 5.36192 11.3886C5.36033 11.2986 5.37689 11.2092 5.4106 11.1258C5.44431 11.0423 5.49448 10.9665 5.55812 10.9029C5.62177 10.8392 5.69758 10.789 5.78103 10.7553C5.86449 10.7216 5.95388 10.7051 6.04387 10.7067C6.13386 10.7082 6.22261 10.7279 6.30483 10.7646C6.38704 10.8012 6.46104 10.854 6.5224 10.9199L9.84675 14.2442V5.13801C9.84675 4.96025 9.91736 4.78977 10.0431 4.66408C10.1687 4.53839 10.3392 4.46777 10.517 4.46777Z"
        fill="currentColor"
      />
    </svg>
  );
};
