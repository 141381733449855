import React from 'react';

export default function CustomModalOverlay({
  open,
  onClose,
  title,
  width = '500px',
  children,
  withCloseButton,
}) {
  if (!open) return null;

  return (
    <div className="modal fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        onClick={onClose}
        className="modal-overlay absolute inset-0 bg-black-1 opacity-80"
      ></div>

      {/* Modal Content */}
      <div
        className="modalBg2 rounded-xl p-10 shadow-lg relative z-10"
        style={{ width }}
      >
        {title && (
          <h2 className="text-2xl font-bold text-white-1 mb-4 border-b-1 border-gray-2/40 pb-4">
            {title}
          </h2>
        )}

        {children}

        {withCloseButton && (
          <div
            onClick={onClose}
            className="text-error-1 hover:opacity-80 transition-all duration-200  rounded-full border border-error-1 flex justify-center items-center absolute top-5 right-[30px] w-[25px] h-[25px]"
          >
            <button className="text-2xl">&times;</button>
          </div>
        )}
      </div>
    </div>
  );
}
